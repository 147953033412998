/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title='', description, image = '', lang, url, author, type, meta, scripts }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            type
          }
        }
      }
    `
    )

    const metaTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;
    const metaAuthor = author || site.siteMetadata.author;
    const metaUrl = url || site.siteMetadata.siteUrl;
    const metaType = type || site.siteMetadata.type;

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={metaTitle}
            titleTemplate={metaTitle}
            meta={[
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:site_name`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `image`,
                    content: image,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:type`,
                    content: metaType,
                },
                {
                    property: `og:url`,
                    content: metaUrl,
                },
                {
                    name: `author`,
                    content: metaAuthor,
                },
            ].concat(meta)}

        >{scripts}</Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
